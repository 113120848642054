import status from '@/vars/status'
import moment from 'moment'
import { apiGetDepts, apiGenNumberRep, apiGenReport, apiGenProd, apiModNote } from '@/api/reporting.api'

const initialState = () => ({
    depsforreps: {},
    depsforrepsselected: '',
    chartinfo: {},
    prodrecs: [],
    jsfreprecs: [],
    jsfreprecsparts: [],
    packoutdates: [],
    packoutiteminfo: [],
    holdcount: 0,
    showholdrecs: false,
})

const state = initialState()

const actions = {
    reset({ commit }) {
        commit('RESET')
    },
    async fetchReport({ commit }, val) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiGenReport(val)
            .then(() => {
                //console.log('REP RETURN')
                //console.log(response.data)

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('FETCHGRAPH ERROR HERE')
                console.log(err)
            })
    },
    /* --- do not use, apiGenReport has new structure  2021-07-27
    async fetchGraph({ commit }, dates) {
        await apiGenReport({ op: 'jsfchart', fromdate: dates.fromdate, todate: dates.todate })
            .then(response => {
                commit('SET_GRAPH', response)
            })
            .catch(function (err) {
                console.log('FETCHGRAPH ERROR HERE')
                console.log(err)
            })
    },
    */
    async fetchDeptList({ commit }) {
        await apiGetDepts({ op: 'deponly' })
            .then(response => {
                commit('SET_DEPSFORREPS', response)
            })
            .catch(function(err) {
                console.log('FETCHDEPTLIST ERROR HERE')
                console.log(err)
            })
    },
    async fetchNumberRep({ state }) {
        await apiGenNumberRep({ dep: state.depsforrepsselected })
            .then(response => {
                window.open('/inc/fetch-file.php?name=' + response.data.file, '_blank')
            })
            .catch(function(err) {
                console.log('FETCHNUMBERREP ERROR HERE')
                console.log(err)
            })
    },
    async fetchProd({ commit }, vals) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        //console.log('GETTING PRODS')
        //console.log(vals)

        await apiGenProd(vals)
            .then(response => {
                commit('SET_PRODRECS', { response: response, reptype: vals.reptype })
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('FETCH PROD ERROR HERE')
                console.log(err)
            })
    },
    async modifyNote({ commit }, vals) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiModNote(vals)
            .then(() => {
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('MODIFY NOTE ERROR HERE')
                console.log(err)
            })
    },
}

const mutations = {
    RESET(state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
    SET_DEPSFORREPS(state, val) {
        state.depsforreps = val.data.recs.deps
    },
    SET_DEPSFORREPSSEL(state, val) {
        state.depsforrepsselected = val
    },
    SET_GRAPH(state, val) {
        state.chartinfo = val.data.recs.graph
        state.jsfreprecs = val.data.recs.numbers
        state.jsfreprecsparts = val.data.recs.parts
    },
    SET_PRODRECS(state, val) {
        //console.log('SETTING PROD RESULT - REP RECS')
        //console.log(val.response.data.recs)
        state.holdcount = 0

        let clearprodrecs = false,
            workprodrecs = val.response.data.recs,
            ess = ''

        if (val.reptype === 200) {
            //console.log('PODS STUFF')
            //console.log(val.response.data.additional.pods)
            for (let podidx = 0; podidx < val.response.data.additional.pods.length; podidx++) {
                state.packoutdates.push(val.response.data.additional.pods[podidx])
            }
        }

        for (let idx = 0; idx < workprodrecs.length; idx++) {
            workprodrecs[idx].alerts = []
            workprodrecs[idx].holdicon = 'mdi-pause-circle-outline'
            workprodrecs[idx].holdcolor = 'red'
            workprodrecs[idx].holdmsg = 'Set hold for '

            if (!workprodrecs[idx].holdstatus) {
                /* --- no op --- */
            } else {
                if (workprodrecs[idx].holdstatus) {
                    workprodrecs[idx].holdicon = 'mdi-play-circle-outline'
                    workprodrecs[idx].holdcolor = 'green'
                    workprodrecs[idx].holdmsg = 'Remove hold from '
                    state.holdcount++
                }
            }

            if (val.reptype === 200) {
                clearprodrecs = true
            } else {
                workprodrecs[idx].fadeit = 'full'
                workprodrecs[idx].staticon = status.indicator[workprodrecs[idx].jsstatus].icon
                workprodrecs[idx].statclass = status.indicator[workprodrecs[idx].jsstatus].class

                /* --- push any alerts in here - only one availabe 2022-01-06 --- */
                if (workprodrecs[idx].havefos) {
                    workprodrecs[idx].alerts.push({ desc: 'Has FOS line item', icon: 'mdi-flare', color: 'orange' })
                }

                if (workprodrecs[idx].holdstatus) {
                    workprodrecs[idx].alerts.push({ desc: 'On HOLD', icon: 'mdi-car-brake-hold', color: 'orange' })
                }

                /* --- 60 days = 5259600 --- */
                if (moment().unix() - workprodrecs[idx].visualts > 5259600) {
                    workprodrecs[idx].suspect = true
                } else {
                    workprodrecs[idx].suspect = false
                }

                if (val.reptype === '2') {
                    if (workprodrecs[idx].storecount > 1) {
                        ess = 's'
                    } else {
                        ess = ''
                    }

                    workprodrecs[idx].packoutstoreinfo =
                        workprodrecs[idx].liinfo + ' for ' + workprodrecs[idx].storecount + ' store' + ess
                }

                /* --- all of the additional ts values provide proper sorting --- */
                if (!workprodrecs[idx].fmshipdate) {
                    /* --- 2 = Tuesday --- */
                    /* --- we're repetitive here to ensure the different values are clean --- */
                    let twoweeksback = moment
                        .unix(workprodrecs[idx].visualts)
                        .subtract(14, 'd')
                        .utcOffset(-5)
                    let datetoshowts = moment
                        .unix(workprodrecs[idx].visualts)
                        .subtract(14, 'd')
                        .utcOffset(-5)
                    let datetoshow = twoweeksback
                    let dowts = datetoshowts.day()
                    let dow = twoweeksback.day()
                    let dowdiffts = dowts - 2
                    let dowdiff = dow - 2

                    if (dowdiff === -1) {
                        /* --- handle a Monday --- */
                        workprodrecs[idx].calcfmshipts = datetoshowts.subtract(6, 'd').unix()
                        datetoshow = datetoshow.subtract(6, 'd').format('MM/DD/YYYY')
                    } else {
                        workprodrecs[idx].calcfmshipts = datetoshowts.subtract(dowdiffts, 'd').unix()
                        datetoshow = datetoshow.subtract(dowdiffts, 'd').format('MM/DD/YYYY')
                    }

                    workprodrecs[idx].calcfmshipclass = ''
                    workprodrecs[idx].calcfmship = '-'
                } else {
                    workprodrecs[idx].calcfmshipclass = ''
                    workprodrecs[idx].calcfmship = workprodrecs[idx].fmshipdate
                    workprodrecs[idx].calcfmshipts = workprodrecs[idx].fmshipts
                }

                let threeweeksback = moment
                    .unix(workprodrecs[idx].calcfmshipts)
                    .subtract(14, 'd')
                    .utcOffset(-5)
                let appdatetoshowts = moment
                    .unix(workprodrecs[idx].calcfmshipts)
                    .subtract(14, 'd')
                    .utcOffset(-5)
                let appdatetoshow = threeweeksback
                let appdowts = appdatetoshowts.day()
                let appdow = threeweeksback.day()
                let appdowdiffts = appdowts - 2
                let appdowdiff = appdow - 2

                if (appdowdiff === -1) {
                    /* --- handle a Monday --- */
                    workprodrecs[idx].calcapprovalshipts = appdatetoshowts.subtract(6, 'd').unix()
                    appdatetoshow = appdatetoshow.subtract(6, 'd').format('MM/DD/YYYY')
                } else {
                    workprodrecs[idx].calcapprovalshipts = appdatetoshowts.subtract(appdowdiffts, 'd').unix()
                    appdatetoshow = appdatetoshow.subtract(appdowdiff, 'd').format('MM/DD/YYYY')
                }

                workprodrecs[idx].calcapprovalshipclass = 'calcedval'
                workprodrecs[idx].calcapprovalship = appdatetoshow

                workprodrecs[idx].artdueshow = moment.unix(workprodrecs[idx].artduets).format('MM/DD/YYYY')

                /* --- hard change  2021-12-09 per ST, approval based on ship must be 3 weeks prior --- */
                workprodrecs[idx].calcapprovalship = moment
                    .unix(workprodrecs[idx].calcfmshipts)
                    .subtract(21, 'd')
                    .utcOffset(-5)
                workprodrecs[idx].calcapprovalship = workprodrecs[idx].calcapprovalship.format('MM/DD/YYYY')

                /* --- 1209600 = 2 weeks --- */
                /* --- 604800 = 1 week --- */
                /* --- !!!calcapprovalship variable name should be changed to exclude the word 'ship'!!! --- */
                if (moment(workprodrecs[idx].calcapprovalship, 'MM/DD/YYYY').unix() - moment().unix() < 604800) {
                    workprodrecs[idx].attention = 'ischecked'
                } else {
                    workprodrecs[idx].attention = ''
                }
            }
        }

        if (clearprodrecs) {
            val.reptype = '2'
            workprodrecs = []
        }

        if (state.showholdrecs) {
            state.prodrecs = workprodrecs
        } else {
            state.prodrecs = []

            for (let idxp = 0; idxp < workprodrecs.length; idxp++) {
                if (!workprodrecs[idxp].holdstatus) {
                    state.prodrecs.push(workprodrecs[idxp])
                }
            }
        }
    },
    SET_SHOWHOLDRECS(state, val) {
        state.showholdrecs = val
    },
}

const getters = {
    getDepsForRepsSel(state) {
        return state.depsforrepsselected
    },
    getDepsForReps(state) {
        return state.depsforreps
    },
    getProdRecs(state) {
        return state.prodrecs
    },
    getPackoutDates(state) {
        return state.packoutdates
    },
    getHoldCount(state) {
        return state.holdcount
    },
    getShowHoldRecs(state) {
        return state.showholdrecs
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
