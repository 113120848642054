//import striptags from 'striptags'
//import stripHtml from 'string-strip-html'
import { apiGetShipInfo, apiGetBrands } from '@/api/shipping.api'

const initialState = () => ({
    shiprecs: [],
    brandshiprecs: [],
    individualshiprecs: [{}], /* --- prevents 'no index 0' error --- */
    selectedfriday: '',
    brands: [],
    selectedbrand: ''
})

const state = initialState()

const actions = {
    reset({ commit }) {
        commit('RESET')
    },
    async fetchShipInfo({ commit }, val) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        //console.log('SENDING SHIPPING')
        //console.log(val)
        //console.log('---')
        //console.log(state.selectedfriday)

        await apiGetShipInfo({ op: val, thefriday: state.selectedfriday, brand: state.selectedbrand })
            .then(response => {
                commit('SET_SHIPRECS', [response.data, val])
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function (err) {
                console.log('FETCHSHIPINFO ERROR HERE')
                console.log(err)
            })
    },
    async fetchIndividual({ commit }, val) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiGetShipInfo(val)
            .then(response => {
                /* --- returns empty array if no ship info --- */
                if (response.data.recs.length) {
                    commit('SET_SHIPRECS', [response.data, 'jsf', val.jsf])
                }

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function (err) {
                console.log('FETCH INDIVIDUAL SHIPINFO ERROR HERE')
                console.log(err)
            })
    },
    async fetchBrands({ commit }) {
        await apiGetBrands()
            .then(response => {
                commit('SET_BRANDS', response.data)
            })
            .catch(function (err) {
                console.log('FETCHBRANDS ERROR HERE')
                console.log(err)
            })
    }
}

const mutations = {
    RESET(state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
    RESET_SHIPRECS(state) {
        state.shiprecs = []
    },
    SET_SHIPRECS(state, val) {
        console.log('VALOUT')
        console.log(val)
        let workrecs = val[0].recs

        for (let idx = 0; idx < workrecs.length; idx++) {
            //workrecs[idx].shiptitle = stripHtml(workrecs[idx].JobKeywordDisplay_WEB.replace(/<br>/g, ', '))
            if (workrecs[idx].JobKeywordDisplay_WEB) {
                workrecs[idx].shiptitle = workrecs[idx].JobKeywordDisplay_WEB.replace(/<br>/g, ', ')
            } else {
                workrecs[idx].shiptitle = val[0].aux.title
            }
            workrecs[idx].pagetitle = ''
            workrecs[idx].jobnumber = ''
            workrecs[idx].jsf = ''

            switch (workrecs[idx].ShipMethod) {
                case 'FedEx':
                    workrecs[idx].linkloc = 'https://www.fedex.com/apps/fedextrack/?tracknumbers=' + workrecs[idx].TrackingNumber + '&cntry_code=us'
                    break
                case 'UPS':
                    /* --- workrecs[idx].linkloc = 'https://www.fedex.com/apps/fedextrack/?tracknumbers=' + workrecs[idx].TrackingNumber + '&accept_UPS_license_agreement=yes&nonUPS_title=QuickBase%20Package%20Tracking%20System' --- */

                    workrecs[idx].linkloc = 'https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=' + workrecs[idx].TrackingNumber + '/trackdetails'
                    break
                case 'Roadway':
                    workrecs[idx].linkloc = 'https://www.fedex.com/apps/fedextrack/?tracknumbers=' + workrecs[idx].TrackingNumber
                    break
                default:
                    workrecs[idx].linkloc = false
            }
        }

        switch (val[1]) {
            case 'brandship':
                state.brandshiprecs = workrecs
                break
            case 'jsf':
                workrecs[0].pagetitle = val[0].aux.title
                workrecs[0].jobnumber = val[0].aux.jobnumber
                workrecs[0].file = val[0].aux.havefile
                workrecs[0].jsf = val[2]

                state.individualshiprecs = workrecs
                break
            default:
                state.shiprecs = workrecs
        }
    },
    SET_SELECTEDFRIDAY(state, val) {
        state.selectedfriday = val
    },
    SET_BRANDS(state, val) {
        state.brands = val
    },
    SET_SELECTEDBRAND(state, val) {
        state.selectedbrand = val
    }
}

const getters = {
    getSelectedFriday(state) {
        return state.selectedfriday
    },
    getShipRecs(state) {
        return state.shiprecs
    },
    getBrandShipRecs(state) {
        return state.brandshiprecs
    },
    getIndividualShipRecs(state) {
        return state.individualshiprecs
    },
    getBrands(state) {
        return state.brands
    },
    getSelectedBrand(state) {
        return state.selectedbrand
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}