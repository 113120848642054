import {
    apiStoreInfo,
    apiSaveStore
} from '@/api/stores.api'

const initialState = () => ({
    title: 'Stores',
    storerecs: [],
    storetypes: [],
    programtypes: [],
    edgecount: 0,
    pecount: 0,
    dmrecs: []
})

const state = initialState()

const actions = {
    reset({ commit }) {
        commit('RESET')
    },
    async fetchStoreTypes({ commit }) {
        await apiStoreInfo({ op: 'gettypes' })
            .then(response => {
                commit('SET_STORETYPES', response.data)
            })
            .catch(function (err) {
                console.log('FETCH STORE TYPES ERROR HERE')
                console.log(err)
            })
    },
    async fetchProgramTypes({ commit }) {
        await apiStoreInfo({ op: 'getprograms' })
            .then(response => {
                commit('SET_PROGRAMTYPES', response.data)
            })
            .catch(function (err) {
                console.log('FETCH PROGRAM TYPES ERROR HERE')
                console.log(err)
            })
    },
    async fetchStores({ commit }) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiStoreInfo({ op: 'getlist' })
            .then(response => {
                console.log('STORES')
                console.log(response.data)

                commit('SET_STORES', response)
                commit('SET_EDGECOUNT', response)
                commit('SET_PECOUNT', response)
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function (err) {
                console.log('FETCHSTORES ERROR HERE')
                console.log(err)
            })
    },
    async fetchDms({ commit }, vals) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiStoreInfo({ op: 'getdms', store: vals.store })
            .then(response => {
                commit('SET_DMS', response)
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function (err) {
                console.log('FETCHDMS ERROR HERE')
                console.log(err)
            })
    },
    async deleteStore({ commit }, vals) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiStoreInfo({ op: 'remove', store: vals.store, userid: vals.userid })
            .then(() => {
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function (err) {
                console.log('DELETE STORE ERROR HERE')
                console.log(err)
            })
    },
    async saveStore({ commit }, vals) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiSaveStore({ op: 'save', store: vals.store, userid: vals.userid })
            .then((ret) => {
                console.log('SAVE STORE')
                console.log(ret)

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function (err) {
                console.log('SAVE STORE ERROR HERE')
                console.log(err)
            })
    },
}

const mutations = {
    RESET(state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
    SET_STORES(state, val) {
        for (let idx = 0; idx < val.data.recs.length; idx++) {
            val.data.recs[idx].assignments = JSON.parse(val.data.recs[idx].assignments)
        }

        state.storerecs = val.data.recs
    },
    SET_STORETYPES(state, val) {
        state.storetypes = val.recs.types
    },
    SET_PROGRAMTYPES(state, val) {
        state.programtypes = val.recs.types
    },
    SET_EDGECOUNT(state, val) {
        state.edgecount = val.data.aux.edgecount
    },
    SET_PECOUNT(state, val) {
        state.pecount = val.data.aux.pecount
    },
    SET_DMS(state, val) {
        state.dmrecs = val.data.recs
    }
}

const getters = {
    getStores(state) {
        return state.storerecs
    },
    getStoreTypes(state) {
        return state.storetypes
    },
    getProgramTypes(state) {
        return state.programtypes
    },
    getEdgeCount(state) {
        return state.edgecount
    },
    getPeCount(state) {
        return state.pecount
    },
    getDms(state) {
        return state.dmrecs
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}