import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
/* --- lazy loading instead --- */
//import Shipping from '@/views/Shipping.vue'
//import Jsf from '@/views/Jsf.vue'
//import Login from '@/views/Login.vue'
//import Reporting from '@/views/Reporting.vue'
//import Utility from '@/views/Utility.vue'

Vue.use(VueRouter)

//  {
//    path: '/print/jsf/:id',
//    name: 'Print Job Start Form',
//    component: () => import(/* webpackChunkName: "jsfprint" */ '@/views/Jsf.vue'),
//    //component: Jsf,
//    meta: { login: true, roles: ['ADMIN'], title: 'Print Job Start Form' }
//  },

const routes = [
  {
    path: '/',
    name: 'JSF List',
    component: () => import(/* webpackChunkName: "jsflist" */ '@/views/Jsf.vue'),
    //component: Jsf,
    meta: { login: true, roles: [], title: 'JSF List' }
  },
  {
    path: '/shipping',
    name: 'Shipping',
    component: () => import(/* webpackChunkName: "shipping" */ '@/views/Shipping.vue'),
    //component: Shipping,
    meta: { login: true, roles: [], title: 'Shipping' }
  },
  {
    path: '/shipping/:id',
    name: 'Shipping /w ID',
    component: () => import(/* webpackChunkName: "shipping" */ '@/views/Shipping.vue'),
    //component: Jsf,
    meta: { login: true, roles: [], title: 'Shipping /w ID' }
  },
  {
    path: '/edit/jsf/:id',
    name: 'Edit Job Start Form',
    component: () => import(/* webpackChunkName: "jsfedit" */ '@/views/Jsf.vue'),
    //component: Jsf,
    meta: { login: true, roles: [], title: 'Edit Job Start Form' }
  },
  {
    path: '/new/jsf',
    name: 'New Job Start Form',
    component: () => import(/* webpackChunkName: "jsfeditnew" */ '@/views/Jsf.vue'),
    //component: Jsf,
    meta: { login: true, roles: [], title: 'New Job Start Form' }
  },
  {
    path: '/admin/depmic',
    name: 'Department & MIC',
    component: () => import(/* webpackChunkName: "dm" */ '@/views/Contact.vue'),
    meta: { login: true, roles: [], title: 'Edit Department & MIC' }
  },
  {
    path: '/admin/depmic/:dep/:mic',
    name: 'Department & MIC DM',
    component: () => import(/* webpackChunkName: "dm" */ '@/views/Contact.vue'),
    meta: { login: true, roles: [], title: 'Edit Department & MIC' }
  },
  {
    path: '/admin/stores',
    name: 'Stores',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/Stores.vue'),
    meta: { login: true, roles: [], title: 'Stores' }
  },
  {
    path: '/admin/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
    meta: { login: true, roles: [], title: 'Edit Contact' }
  },
  {
    path: '/admin/contact/:dep/:mic',
    name: 'Contact DM',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
    meta: { login: true, roles: [], title: 'Edit Contact' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    //component: Login,
    meta: { login: false, roles: [], title: 'Login' }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '@/views/Reporting.vue'),
    //component: Reporting,
    meta: { login: true, roles: ['ADMIN'], title: 'Reporting' }
  },
  {
    path: '/reports/:repname',
    name: 'Reports - w/Name',
    component: () => import(/* webpackChunkName: "reportspackout" */ '@/views/Reporting.vue'),
    //component: Reporting,
    meta: { login: true, roles: ['ADMIN', 'COORDINATOR'], title: 'Reporting' }
  },
  {
    path: '/quickreports/:repname/:authkey',
    name: 'Quickreports',
    component: () => import(/* webpackChunkName: "reportspackout" */ '@/views/Quickreports.vue'),
    meta: { login: false, roles: [process.env.VUE_APP_QR_KEY], title: 'Quick Reports' }
  },
  {
    path: '/utility/price',
    name: 'Utilities',
    component: () => import(/* webpackChunkName: "utility" */ '@/views/Utility.vue'),
    //component: Utility,
    meta: { login: true, roles: ['ADMIN'], title: 'System Utilities' }
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { login: false, roles: [], title: 'Logout' },
    beforeEnter(to, from, next) {
      store.dispatch('Sgs/processLogout', null, { root: true })
      next('/login')
    }
  },
  { path: '*', name: 'Page Not Found', component: () => import(/* webpackChunkName: "notfound" */ '@/views/NotFound.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let workpath = to.path.split('/'),
    addltitle = ''

  if (workpath[1] === 'v1') {
    return false
  }
  /*
  console.log('------------------------------')
  console.log('FROM')
  console.log(from)
  console.log('TO')
  console.log(to)
  console.log('NEXT')
  console.log(next)
  console.log('------------------------------')
  */

  store.dispatch('Sgs/checkAuth', null, { root: true })
    .then(() => {
      let pathlook = to.path.split('/')

      /*--- need report name (2) and auth key (3) --- */
      if (pathlook.length > 3 && pathlook[1] === 'quickreports' && !store.getters['Sgs/getLoggedIn']) {
        if (pathlook[3] === '') {
          next('/login')
        } else {
          if (to.meta.roles.indexOf(to.params.authkey) !== -1) {
            next()
          } else {
            next('/login')
          }
        }
      } else {
        if (to.path === '/login' && store.getters['Sgs/getLoggedIn']) {
          next('/')
        }

        if (to.path !== '/login' && !store.getters['Sgs/getLoggedIn']) {
          router.push('/login')
            .catch(() => {
              /* --- suppress error --- */
            })
          /*
          next('/login')
            .then((ret) => {
              console.log('REROUTE TO LOGIN')
              console.log(ret)
            })
            .catch((err) => {
              console.log('REROUTE TO LOGIN - ERR')
              console.log(err)
            })
            */
        } else {
          next()
        }

        if (store.getters['Sgs/getLoggedIn']) {
          if (to.matched.some(record => record.meta.login) && !store.getters['Sgs/getLoggedIn']) {
            next('/')
          } else {
            if (to.meta.roles.length) {
              store.dispatch('Sgs/checkValidRole', to.meta.roles, { root: true })
                .then((ret) => {
                  if (ret) {
                    /* --- role allowed, go there --- */
                    next()
                  } else {
                    next('/')
                  }
                })
                .catch(function () {
                  next('/')
                })
            } else {
              next()
            }
          }
        }
      }
    })
    .catch(err => {
      console.log('ROUTER CHECK ERROR HERE')
      console.log(err)
    })

  if (to.params.repname) {
    addltitle = ' - ' + to.params.repname.charAt(0).toUpperCase() + to.params.repname.slice(1)
  }

  store.commit('Sgs/SET_PAGENAME', to.meta.title + addltitle)
})

export default router
