import httpClient from './httpClient'

const apiGetJsfList = val => httpClient.post('/inc/rest/Jsf/RetrieveJsfList', val),
    apiSetJsfLock = val => httpClient.put('/inc/rest/' + val.path, {}),
    apiGetProgsAndStores = val => httpClient.post('/inc/jsfeditv2.php', val),
    apiMakeModGetJsf = val => httpClient.post('/inc/jsfeditv2.php', val),
    apiGetLiForProg = val => httpClient.get('/inc/rest/Program/GetTaraData/' + val.program),
    apiSetVisualDates = val => httpClient.post('/inc/datefixerv2.php', val),
    apiGetZeroPrice = () => httpClient.post('/inc/zeroprice.php', { op: 'getnoprice' })

export {
    apiGetJsfList,
    apiSetJsfLock,
    apiGetProgsAndStores,
    apiMakeModGetJsf,
    apiGetLiForProg,
    apiSetVisualDates,
    apiGetZeroPrice,
}
