<template>
    <div class="d-print-none">
        <v-card>
            <v-navigation-drawer permanent expand-on-hover clipped app>
                <v-list nav dense>
                    <v-list-item-group v-if="user.loggedin">
                        <v-list-item v-for="(item, i) in items" :key="i" :to="{ path: item.gohere }">
                            <v-list-item-icon>
                                <v-icon v-html="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <v-list-group v-if="user.showadmin" prepend-icon="mdi-cogs">
                    <template v-slot:activator>
                        <v-list-item-title>Admin</v-list-item-title>
                    </template>
                    <v-list-item class="pl-8" v-for="(uitem, i) in utilitems" :key="i" link :to="uitem.gohere">
                        <v-list-item-icon>
                            <v-icon small v-html="uitem.icon"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-title v-text="uitem.title"></v-list-item-title>
                    </v-list-item>
                    <v-flex v-if="user.showreportadmin">
                        <v-list-item class="pl-8" v-for="(aitem, i) in adminitems" :key="i" link :to="aitem.gohere">
                            <v-list-item-icon>
                                <v-icon small v-html="aitem.icon"></v-icon>
                            </v-list-item-icon>

                            <v-list-item-title v-text="aitem.title"></v-list-item-title>
                        </v-list-item>
                    </v-flex>
                    <v-flex v-if="user.showreportclient">
                        <v-list-item
                            class="pl-8"
                            v-for="(critem, i) in clientreporteritems"
                            :key="i"
                            link
                            :to="critem.gohere"
                        >
                            <v-list-item-icon>
                                <v-icon small v-html="critem.icon"></v-icon>
                            </v-list-item-icon>

                            <v-list-item-title v-text="critem.title"></v-list-item-title>
                        </v-list-item>
                    </v-flex>
                </v-list-group>
            </v-navigation-drawer>
        </v-card>
        <v-app-bar fixed app extended clipped-left class="dillards">
            <v-flex xs2 class="respond">
                <a href="/">
                    <img src="https://dillards.stevensoncolor.com/images/dillards-smaller.png" />
                </a>
            </v-flex>
            <v-divider class="mr-3" inset vertical></v-divider>
            <!-- v-icon large color="white" class="ml-5 mr-5 mt-8" @click.stop="drawer = !drawer">
                mdi-microsoft-xbox-controller-menu
            </v-icon -->
            <v-toolbar-title class="mt-5" v-text="title + ' - ' + pagename + whereami"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-flex xs2 class="mt-5" v-if="user.loggedin">
                <!-- v-menu transition="slide-y-transition" bottom>
                    <v-btn slot="activator" class="purple" color="primary" dark>
                        {{ user.name }}
                    </v-btn>
                    <v-list>
                        <v-list-tile v-for="(unav, ni) in usernav" :key="ni" :to="{ path: unav.gohere }">
                            <v-list-tile-title>{{ unav.title }}</v-list-tile-title>
                        </v-list-tile>
                    </v-list>
                </v-menu -->
                <v-menu open-on-hover top offset-y>
                    <template v-slot:activator="{ on }">
                        <v-flex v-on="on">
                            <v-icon color="white">mdi-account-circle-outline</v-icon>
                            {{ user.name }}
                        </v-flex>
                    </template>
                    <v-list dense>
                        <v-list-item
                            style="cursor: pointer"
                            v-for="(unav, ni) in usernav"
                            :key="ni"
                            :to="{ path: unav.gohere }"
                        >
                            <v-list-item-title>{{ unav.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-flex>
            <!--v-flex xs1 class="mt-5" v-if="user.loggedin">
                <v-icon large color="white darken-1" @click="gohelp"> mdi-lifebuoy </v-icon>
            </v-flex -->
            <v-flex xs1 class="mt-5" v-if="user.loggedin && deptmic.displaymissing">
                <v-tooltip bottom v-if="deptmic.missinginfo || dm.allnoassigns">
                    <v-badge overlap color="red" slot="activator">
                        <template slot="badge">
                            <v-icon dark small> error_outline </v-icon>
                        </template>
                        <v-icon large color="white darken-1" @click="gocontact"> account_box </v-icon>
                    </v-badge>
                    <div v-if="deptmic.missinginfo">There is missing contact information.</div>
                    <div v-if="dm.allnoassigns">There are missing store assignments.</div>
                </v-tooltip>
                <!-- lazy repetition here -->
                <v-tooltip bottom v-if="!deptmic.missinginfo && !dm.allnoassigns">
                    <v-badge overlap color="green" slot="activator">
                        <template slot="badge">
                            <v-icon dark small> check_circle_outline </v-icon>
                        </template>
                        <v-icon large color="white darken-1" @click="gocontact"> account_box </v-icon>
                    </v-badge>
                    <div>All contact info is in place.</div>
                </v-tooltip>
            </v-flex>
            <v-flex xs2 class="rightside respond">
                <img src="https://dillards.stevensoncolor.com/images/sgs-smaller.png" />
            </v-flex>
            <v-row slot="extension">
                <v-flex xs12>
                    <v-progress-linear
                        dense
                        v-if="refreshing"
                        transition="scroll-y-transition"
                        indeterminate
                        color="grey lighten-1"
                        background-color="transparent"
                        background-opacity="0.3"
                        stream
                    ></v-progress-linear>
                </v-flex>
            </v-row>
        </v-app-bar>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
//{ icon: 'mdi-file', title: 'Summary', gohere: '/summary' },

/* eslint-disable no-console */
export default {
    name: 'Navigation',
    computed: {
        ...mapGetters('Sgs', ['getDrawer']),
        ...mapState('Sgs', ['user', 'drawer', 'usernav', 'deptmic', 'pagename', 'refreshing']),
        drawer: {
            get() {
                return this.getDrawer
            },
            set(val) {
                this.$store.commit('Sgs/SET_DRAWER', val)
            },
        },
    },
    data: () => ({
        clipped: false,
        displayadmin: false,
        adminallow: ['stevenson', 'admin', 'contract'],
        showstage: false,
        whereami: '',
        items: [
            { icon: 'mdi-receipt', title: 'Job Start Forms', gohere: '/' },
            { icon: 'mdi-truck', title: 'Ship Tracking', gohere: '/shipping' },
        ],
        utilitems: [
            { icon: 'mdi-door-open', title: 'Dept & MIC', gohere: '/admin/depmic' },
            { icon: 'mdi-contacts', title: 'Contacts', gohere: '/admin/contact' },
            { icon: 'mdi-store', title: 'Stores', gohere: '/admin/stores' },
        ],
        adminitems: [
            { icon: 'mdi-chart-box', title: 'Reporting', gohere: '/reports' },
            { icon: 'mdi-cog', title: 'System Utilities', gohere: '/utility/price' },
        ],
        clientreporteritems: [{ icon: 'mdi-call-split', title: 'Packout Report', gohere: '/reports/packout' }],
        reportitems: [{ icon: 'date_range', title: 'Date Snapshot', gohere: '/reports/snapshot' }],
        title: "Dillard's JSF Management",
    }),
    mounted() {
        //if (process.env.VUE_APP_HOST === 'dillards-stage' || process.env.VUE_APP_HOST === 'dillards-dev') {}
        if (process.env.NODE_ENV !== 'production') {
            this.showstage = true
            this.whereami = ' [' + process.env.NODE_ENV + ']'
        }

        this.$store.dispatch('Sgs/fetchPartList', null, { root: true })
    },
    methods: {
        /*
        moddrawer() {
            this.$store.commit('UPDATE_DRAWER', !this.drawer)
        },
        */
        checkme(priv) {
            if (this.$store.dispatch('checkprivs', [priv])) {
                return true
            } else {
                return false
            }
        },
        gocontact() {
            this.$router.push({ path: '/admin/contacts' })
        },
        gohelp() {
            this.$router.push({ path: '/help' })
        },
    },
}
</script>

<style scoped>
.theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: #484848;
}
header.dillards {
    background-color: #484848;
    color: #ffffff;
    background-image: linear-gradient(to right, #484848, #cccccc);
}

.centerit {
    margin-top: 4rem;
    padding-top: 1rem;
}

.centerit div {
    text-align: center;
}

.respond img {
    margin-top: 4rem;
    width: 100%;
    height: auto;
}

.rightside {
    margin-left: auto;
}

.v-list {
    padding: 0;
}
</style>