import router from '@/router'
import { apiAuthenticate, apiGetPartList, apiFileOps } from '@/api/sgs.api'

const initialState = () => ({
    failLogin: false,
    user: {},
    adminmenuroles: ['ADMIN', 'COORDINATOR', 'MODERATOR', 'CREATOR'],
    adminviewroles: ['ADMIN', 'MODERATOR'],
    reportadminroles: ['ADMIN'],
    sysadminroles: ['ADMIN'],
    reportclientroles: ['COORDINATOR'],
    loginNotice: 'Either the username or password are incorrect, please try again.',
    drawer: false /* --- may not be needed --- */,
    pagename: '',
    refreshing: false,
    showmsg: false,
    msg: '',
    msgcolor: 'info',
    msgtimeout: '-1',
    msgtpos: false,
    msgcpos: true,
    partlist: [],
    usernav: [{ title: 'Logout', gohere: '/logout' }],
    deptmic: {
        selected: '',
        savemsg: false,
        savemsgerr: false,
        buyer: '',
        buyeremail: '',
        assistant: '',
        assistantemail: '',
        artapprovalname: '',
        artapprovalemail: '',
        dmm: '',
        dmmemail: '',
        vendorcontact: '',
        vendoremail: '',
        vendorphone: '',
        company: '',
        area: '',
        house: '',
        brand: '',
        search: '',
        templates: [],
        storereassign: false,
        missinginfo: false,
        missingselected: '',
        missingstoresel: '',
        displaymissing: false,
        uploadingfile: false,
    },
})

const state = initialState()

const actions = {
    reset({ commit }) {
        commit('RESET')
    },
    async checkValidRole({ state }, set) {
        for (let key in state.user.roles) {
            for (let si = 0; si < set.length; si++) {
                if (state.user.roles[key] === set[si]) {
                    return true
                }
            }
        }

        return false
    },
    async checkAuth({ commit }) {
        await apiAuthenticate({ op: 'check' })
            .then(response => {
                let userdata = {}

                /* --- this is a bit sloppy, moving to strapi will obviate the need for this   2024-04-24 --- */
                if (process.env.VUE_APP_VERSION.indexOf('CL-DEV-LOCAL') > -1) {
                    console.log('SETTING USER')
                    userdata = {
                        status: 'success',
                        message: 'logged in',
                        info: {
                            roles: { '1': 'ADMIN' },
                            name: 'Scott Theissen',
                            oldinfo: {
                                un: 'stheissen',
                                id: 22,
                                ur: 'stevenson',
                            },
                            uid: 24,
                            email: 'scott.theissen@sgsco.com',
                            username: 'stheissen',
                        },
                    }
                } else {
                    console.log('GETTING USER')
                    userdata = response.data
                }

                commit('SET_USER', userdata)
            })
            .catch(function(err) {
                console.log('CHECKAUTH ERROR HERE')
                console.log(err)
            })
    },
    async processLogout({ commit }) {
        await apiAuthenticate({ op: 'logout' })
            .then(response => {
                if (response) {
                    commit('RESET')
                    commit('SET_USER', {})
                }
            })
            .catch(function(err) {
                console.log('PROCESSLOGOUT ERROR HERE')
                console.log(err)
            })
    },
    async processLogin({ commit, dispatch }, who) {
        await apiAuthenticate({ op: 'login', u: who.u, p: who.p })
            .then(response => {
                if (response.data.status === 'success') {
                    commit('SET_FAILLOGIN', false)
                    commit('SET_USER', response.data)

                    router.replace('/')
                    /* --- get initial list --- */
                    dispatch('Jsf/fetchList', null, { root: true })
                } else {
                    switch (response.data.message) {
                        case 'Too many requests':
                            commit('SET_LOGINNOTICE', 'Too many attempts, contact an administrator.')
                            commit('SET_FAILLOGIN', true)
                            break
                        case 'Not logged in, log out irrelevant':
                            /* ---- stupid recursion, ignore --- */
                            break
                        case 'auth NOT set':
                            /* ---- returned from check op, no need to logout or change notice --- */
                            //router.push({path: '/login'})
                            break
                        default:
                            /* --- failed for some other reason --- */
                            //dispatch('processLogout')
                            commit(
                                'SET_LOGINNOTICE',
                                'Either the username or password are incorrect, please try again.'
                            )
                            commit('SET_FAILLOGIN', true)
                    }
                }
            })
            .catch(function(err) {
                console.log('PROCESSLOGIN ERROR HERE')
                console.log(err)
            })
    },
    async fetchPartList({ commit }) {
        await apiGetPartList()
            .then(response => {
                if (response) {
                    commit('SET_PARTLIST', response.data)
                }
            })
            .catch(function(err) {
                console.log('FETCHPARTLIST ERROR HERE')
                console.log(err)
            })
    },
    async sendSheet({ commit }, vals) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        switch (vals.op) {
            case '0':
            case '1':
                vals.filetouse = 'gen-spreadsheetv2.php'
                break
            case '2':
                vals.filetouse = 'gen-spreadsheet-packoutv2.php'
                break
            case 'storelist':
                vals.filetouse = 'gen-spreadsheet-storelistv2.php'
                break
            case 'dmstores':
                /* --- do we need this? --- */
                if (vals.type.indexOf('allmics') > -1 || vals.type.indexOf('thismic') > -1) {
                    vals.filetouse = 'gen-spreadsheet-storeassignv2.php'
                }
                break
        }

        await apiFileOps(vals)
            .then(response => {
                window.open('/inc/fetch-file.php?name=' + response.data.file, '_blank')

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('FILE OP ERROR HERE')
                console.log(err)
            })
    },
}

const mutations = {
    RESET(state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
    RESETMSG(state) {
        state.showmsg = false
        state.msg = ''
        state.msgcolor = 'info'
        state.msgtimeout = '-1'
        state.msgtpos = false
        state.msgcpos = true
    },
    SET_MSG(state, val) {
        state.showmsg = val[0]
        state.msg = val[1]

        if (val[2]) {
            state.msgcolor = val[2]
        }

        if (val[3]) {
            state.msgtimeout = val[3]
        }

        if (val[4]) {
            switch (val[4]) {
                case 'c':
                    state.msgtpos = false
                    state.msgcpos = true
                    break
                case 't':
                    state.msgtpos = true
                    state.msgcpos = false
                    break
            }
        }
    },
    SET_FAILLOGIN(state, val) {
        state.failLogin = val
    },
    SET_USER(state, val) {
        if (val.status === 'success') {
            state.user = val.info
            state.user.loggedin = true
            state.user.showadmin = false
            state.user.showreportadmin = false
            state.user.showreportclient = false
            state.user.isadmin = false

            for (let key in state.user.roles) {
                for (let si = 0; si < state.adminmenuroles.length; si++) {
                    if (state.user.roles[key] === state.adminmenuroles[si]) {
                        state.user.showadmin = true
                    }
                }

                for (let si = 0; si < state.reportadminroles.length; si++) {
                    if (state.user.roles[key] === state.reportadminroles[si]) {
                        state.user.showreportadmin = true
                    }
                }

                for (let si = 0; si < state.reportclientroles.length; si++) {
                    if (state.user.roles[key] === state.reportclientroles[si]) {
                        state.user.showreportclient = true
                    }
                }

                for (let si = 0; si < state.sysadminroles.length; si++) {
                    if (state.user.roles[key] === state.sysadminroles[si]) {
                        state.user.isadmin = true
                    }
                }
            }
        } else {
            state.user.loggedin = false
            state.user.showadmin = false
            state.user.showreportadmin = false
            state.user.showreportclient = false
            state.user.isadmin = false
        }

        /* --- DEV --- */
        if (process.env.VUE_APP_LOCAL == 1) {
            state.user.roles = { 1: 'ADMIN' }
            state.user.loggedin = true
            state.user.showadmin = true
            state.user.showreportadmin = true
            state.user.isadmin = true
            state.user.email = 'scott.theissen@sgsco.com'
            state.user.name = 'Scott Theissen'
            state.user.username = 'stheissen'
            //state.user.showreportclient = true
        }
    },
    SET_DRAWER(state, val) {
        state.drawer = val
    },
    SET_LOGINNOTICE(state, val) {
        state.loginNotice = val
    },
    SET_PAGENAME(state, val) {
        state.pagename = val
    },
    SET_REFRESHING(state, val) {
        state.refreshing = val
    },
    SET_PARTLIST(state, val) {
        state.partlist = val.partlist
    },
}

const getters = {
    getLoggedIn(state) {
        return state.user.loggedin
    },
    getAdminView(state) {
        let adminview = false

        for (const role in state.user.roles) {
            if (state.adminviewroles.includes(state.user.roles[role])) {
                adminview = true
            }
        }

        return adminview
    },
    getRoles(state) {
        let roleset = []

        for (const role in state.user.roles) {
            roleset.push(state.user.roles[role])
        }

        return roleset
    },
    getUser(state) {
        return state.user
    },
    getDrawer(state) {
        return state.drawer
    },
    getShowMsg(state) {
        return state.showmsg
    },
    getMsg(state) {
        return state.msg
    },
    getMsgColor(state) {
        return state.msgcolor
    },
    getPartList(state) {
        return state.partlist
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
